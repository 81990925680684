import React from "react";
import { useStaticQuery, graphql } from 'gatsby';

import Header from '../../components/header';
import Footer from '../../components/footer';
import Seo from "../../components/seo";

import Banner from "./banner";
import Filter from "./filter";
import LastProducts from "./last-projects";
import BannerInbound from "./banner-inbound";
import MoreViewedProjects from "./more-viewed-projects";
import MoreLikedProjects from "./more-liked-projects";

import Newsletter from '../../components/newsletter';

export default function Home() {
  const results = useStaticQuery(graphql`
    {
      seo: prismicSeoHomeMeuAmbiente {
        data {
          canonical {
            url
          }
          meta_description
          meta_og_description
          meta_og_image {
            url
          }
          meta_og_title
          meta_og_type
          meta_og_url {
            url
          }
          meta_title
        }
      }
    }`)

  const { seo } = results

  return (
    <>
      {
        seo && (
          <Seo
            metaTitle={seo.data.meta_title}
            metaDescription={seo.data.meta_description}
            title={seo.meta_og_title}
            description={seo.meta_og_description}
            image={seo.meta_og_image ? seo.meta_og_image.url : ''}
            url={seo.data.meta_og_url ? seo.data.meta_og_url : ""}
            type={seo.meta_og_type}
          />
        )
      }
      <Header />
      <Banner />
      <Filter />
      <LastProducts />
      <BannerInbound />
      <MoreViewedProjects />
      <MoreLikedProjects />
      <Newsletter />
      <Footer />
    </>
  )
}
