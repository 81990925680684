import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ContainerDefault from "../../components/container";
import ProjectCard from "../../components/project-card";
import api from "../../services/api";

const Container = styled.div`
  position: relative;
  padding-bottom: 35px;

  .more-liked-header {
    margin-bottom: 35px;
    height: 80px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font: italic bold 26px "Times new roman";
      color: #000000;
      max-width: 155px;
    }
  }

  .actions-slider {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;

    button {
      background: #ffffff;
      width: 80px;
      height: 80px;
      border-radius: 3px;
      font-size: 0;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: 0.2s all ease;

      &.arrow-right {
        background: #ffffff url("/images/arrow-right-bold.png") no-repeat center;
        margin-left: 15px;
      }
      &.arrow-left {
        background: #ffffff url("/images/arrow-left-bold.png") no-repeat center;
      }

      &:hover {
        opacity: 0.6;
      }
      &.disable {
        cursor: default;
        opacity: 0.6;
      }
    }
  }

  @media (min-width: 767px) {
    padding-bottom: 40px;

    .more-liked-header {
      margin-bottom: 25px;

      h2 {
        max-width: 100%;
      }
    }

    .project-item {
      margin: 0 4px;
    }
    .react-multi-carousel-list {
      margin: 0 -4px;
    }
  }

  @media (min-width: 1200px) {
    padding-bottom: 70px;

    .project-item {
      margin: 0 8px;
    }
    .react-multi-carousel-list {
      margin: 0 -8px;
    }
  }
`;

export default function MoreLikedProjects() {
  const [projects, setProjects] = useState([]);

  async function favoritesAPI() {
    const response = await api.get(
      "https://apimeuambiente.guararapes.com.br/project/byLike/"
    );
    setProjects(response.data);
  }

  useEffect(() => {
    favoritesAPI();
  }, []);

  return (
    <ContainerDefault>
      <Container>
        <div className="more-liked-header">
          <h2>Projetos mais curtidos</h2>
        </div>

        <Carousel
          additionalTransfrom={0}
          arrows={false}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={true}
          renderDotsOutside={false}
          customButtonGroup={<ButtonGroup />}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1200,
              },
              items: 3,
            },
            mobile: {
              breakpoint: {
                max: 767,
                min: 0,
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1200,
                min: 767,
              },
              items: 2,
            },
          }}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {projects.map((project, index) => {
            return (
              <div className="project-item" key={index}>
                <ProjectCard
                  id={project.id}
                  image={project.gallery}
                  name={project.name}
                  city={project.city}
                  uf={project.uf}
                  authorImage={
                    project.user.company_avatar !== null
                      ? project.user.company_avatar
                      : "/images/01.png"
                  }
                  authorName={project.user.company_name}
                  numberOfLikes={project.likes}
                  products={project.products}
                />
              </div>
            );
          })}
        </Carousel>
      </Container>
    </ContainerDefault>
  );
}

const ButtonGroup = ({ next, previous, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;

  return (
    <div className="actions-slider">
      <button
        aria-label="Go to previous slide"
        className={
          currentSlide === 0 ? "disable arrow-left" : "left arrow-left"
        }
        onClick={() => previous()}
      ></button>
      <button
        aria-label="Go to next slide"
        className={
          currentSlide === totalItems - slidesToShow
            ? "disable arrow-right"
            : "right arrow-right"
        }
        onClick={() => next()}
      ></button>
    </div>
  );
};
