import React, { useEffect, useState } from "react";
import styled from "styled-components";
import api from "../../services/api";

import ContainerDefault from "../../components/container";

import ProjectCard from "../../components/project-card";

import Button from "../../components/button";

const Container = styled.section`
  padding-top: 56px;

  h2 {
    font: italic bold 26px "Times new roman";
    color: #000000;
    margin-bottom: 35px;
    text-align: center;

    span {
      font: italic bold 26px "Times new roman";
      position: relative;

      &::before {
        content: "";
        border-bottom: 3px solid #f1a857;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }

  .projects-list {
    li {
      margin-bottom: 30px;
    }
  }

  .view-more {
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 767px) {
    padding-top: 45px;

    h2 {
      margin-bottom: 45px;
    }

    .projects-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 8px;
      grid-row-gap: 24px;

      li {
        margin: 0;

        &:first-child {
          grid-column: 1 / -1;

          .image-container {
            height: 404px;

            .hover-image {
              padding-top: 125px;
            }
          }
          .project-info {
            padding: 16px 35px 21px;

            .project-info-footer {
              .author img {
                width: 46px;
                height: 46px;
              }

              .likes i {
                width: 29px;
                height: 30px;
              }
            }
          }
        }
      }
    }

    .view-more {
      margin-top: 45px;
    }
  }

  @media (min-width: 1200px) {
    padding-top: 45px;

    h2 {
      margin-bottom: 47px;
    }

    .projects-list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 30px;

      li {
        margin: 0;
        grid-column: auto / span 2;

        &:nth-child(1),
        &:nth-child(2) {
          grid-column: auto / span 3;

          .image-container {
            height: 404px;

            .hover-image {
              padding-top: 125px;
            }
          }
          .project-info {
            padding: 16px 35px 21px;

            .project-info-footer {
              .author img {
                width: 46px;
                height: 46px;
              }

              .likes i {
                width: 29px;
                height: 30px;
              }
            }
          }
        }
      }
    }

    .view-more {
      margin-top: 42px;
    }
  }
`;

export default function LastProducts() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    async function favoritesAPI() {
      const response = await api.get(
        "https://apimeuambiente.guararapes.com.br/project/byNew/"
      );
      setProjects(response.data);
    }
    favoritesAPI();
  }, []);

  return (
    <ContainerDefault>
      <Container>
        <h2>
          <span>Últimos</span> projetos cadastrados
        </h2>

        {projects.length > 0 && (
          <ul className="projects-list">
            {projects.map((project) => (
              <li key={project.id}>
                <ProjectCard
                  id={project.id}
                  image={project.gallery}
                  name={project.name}
                  city={project.city}
                  uf={project.uf}
                  authorImage={
                    project.user.company_avatar !== null
                      ? project.user.company_avatar
                      : "/images/01.png"
                  }
                  authorName={project.user.company_name}
                  numberOfLikes={project.likes}
                  products={project.products}
                />
              </li>
            ))}
          </ul>
        )}

        <div className="view-more">
          <Button
            name="Ver todos os projetos"
            to="/ambientes"
            iconName="arrow-right"
          />
        </div>
      </Container>
    </ContainerDefault>
  );
}
