import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import api from '../../services/api';
import { navigate, useStaticQuery, graphql } from 'gatsby';

const ContainerOut = styled.section`
  width: 100%;
  background: #ffffff;
  border-bottom: 4px solid #F1A857;
  padding-top: 34px;
  padding-bottom: 34px;

  .container {
    padding: 0 20px;
    margin: 0 auto;
  }

  .action {
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      cursor: pointer;
      width: auto;
      height: 60px;
      border-radius: 3px;

      background: #F1A857;

      display: flex;
      align-items: center;

      transition: .2s all ease;

      span {
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        padding-left: 29px;
        padding-right: 17px;

        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      i {
        width: 55px;
        height: 100%;
        border-left: 1px solid #D99440;
        margin-left: auto;
        background: url('/images/arrow-right.png') center no-repeat;
      }

      &:hover {
        background: #E59134;

        i {
          border-left: 1px solid #CD8226;
        }
      }
    }
  }

  .filter-options {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .filter-option {
      display: none;
  
      &.show {
        display: flex;
      }
      justify-content: center;
      
      align-items: center;
      margin-bottom: 20px;

      p {
        font: italic normal 16px 'Times new roman';
        color: #747474;
      }

      select {
        margin-left: 5px;
        border: 0;
        border-bottom: 1px solid #b3b3b3;
        max-width: 130px;
        color: #323232;
        padding: 6px 25px 6px 2px;
        font: italic bold 16px 'Times new roman';
        appearance: none;
        border-radius: 0;
        
        background: url('/images/arrow-down.png') no-repeat center;
        background-position: 95%;
      }
    }
  }


  @media (min-width: 767px) {
    padding-top: 40px;
    padding-bottom: 45px;

    .container {
      max-width: 767px;
    }

    .filter-options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      .filter-option {
        margin-bottom: 0;
        display: flex;

        p {
          font: italic normal 18px 'Times new roman';
        }

        select {
          max-width: inherit;
          min-width: 100px;
          font: italic bold 18px 'Times new roman';
        }
      }
    }

    .action {
      margin-top: 40px;

      a {
        height: 45px;
        width: 210px;
      }
    }
  }

  @media (min-width: 1200px) {
    padding-top: 55px;
    padding-bottom: 55px;

    .container {
      max-width: 1200px;
      padding: 0 14px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .filter-options .filter-option {
        margin-right: 20px;

        p {
          font: italic normal 18px 'Times new roman';
        }

        select {
          font: italic bold 18px 'Times new roman';
        }
      }
    }

    .action {
      margin-top: 0;

      a {
        height: 60px;
        /* width: auto; */
      }
    }
  }
`;

export default function Filter(props) {
  const results = useStaticQuery(graphql`
    {
      tipo_projeto: allPrismicTipoDeProjeto {
        edges {
          node {
            data {
              imagem {
                url
              }
              titulo {
                text
              }
            }
            uid
          }
        }
      }
      tonalidade_produto: allPrismicTonalidadeProduto(filter: {lang: {eq: "pt-br"}}) {
        edges {
          node {
            data {
              imagem {
                url
              }
              nome {
                text
              }
            }
            uid
          }
        }
      }
      design_do_produto: allPrismicDesignDoProduto(filter: {lang: {eq: "pt-br"}}) {
        edges {
          node {
            data {
              imagem {
                url
              }
              nome {
                text
              }
            }
            uid
          }
        }
      }
    }
  `)

  const { design_do_produto, tonalidade_produto, tipo_projeto } = results

  const [typeSelected, setTypeSelected] = useState(tipo_projeto.edges[0].node.uid);
  const [hueSelected, setHueSelected] = useState(tonalidade_produto.edges[0].node.uid);
  const [designSelected, setDesignSelected] = useState(design_do_produto.edges[0].node.uid);
  const [step2, setSetStep2] = useState(false);
  const [step3, setSetStep3] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  function handleFilter(e) {
    e.preventDefault();
    let url = [];

    if (typeSelected)
      url.push(`tipo-de-projeto=${typeSelected}`);

    if (designSelected)
      url.push(`design=${designSelected}`);

    if (hueSelected)
      url.push(`tonalidade=${hueSelected}`);

    navigate(`/ambientes/?${url.join("&")}`);
  }

  const resizeCarousel = () => {

    setTypeSelected('')
    setHueSelected('')
    setDesignSelected('')

    if (document.body.clientWidth > 540) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

  }

  useEffect(() => {
    resizeCarousel();
  }, [props]);

  return (
    <ContainerOut>
      <div className="container">
        <div className="filter-options">
          <div className="filter-option show">
            <p>Quero ver projetos de</p>
            <select onChange={e => { setTypeSelected(e.target.value); setSetStep2(true) }} value={ typeSelected }>
              <option value="">Todos</option>
              {
                tipo_projeto.edges.map((item, index) => {
                  return (
                    <option value={item.node.data.titulo.text} key={index}>{item.node.data.titulo.text}</option>
                  )
                })
              }
            </select>
          </div>
          <div className={ ['filter-option', step2 ? 'show' : ''].join(' ') }>
            <p>com efeito de</p>
            <select onChange={e => {setDesignSelected(e.target.value); setSetStep3(true) }} value={ designSelected }>
              <option value="">Todos</option>
              {
                design_do_produto.edges.map((item, index) => {
                  return (
                    <option value={item.node.uid} key={index}>{item.node.data.nome.text}</option>
                  )
                })
              }
            </select>
          </div>
          <div className={ ['filter-option', step3 ? 'show' : ''].join(' ') }>
            <p>ou tons de</p>
            <select onChange={e => setHueSelected(e.target.value)} value={ hueSelected }>
              <option value="">Todos</option>
              {
                tonalidade_produto.edges.map((item, index) => {
                  return (
                    <option value={item.node.uid} key={index}>{item.node.data.nome[0].text}</option>
                  )
                })
              }
            </select>
          </div>
        </div>

        <div className="action">
          <button type="button" onClick={handleFilter}>
            <span>Ver projetos</span>
            <i></i>
          </button>
        </div>
      </div>
    </ContainerOut>
  )
}