import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"

const Container = styled.div`
  background: #DEDEDE;
  padding: 40px 0;
  height: 174px;
  @media (max-width: 789px) {
    height:300px;
  }
  .wrapper {
    max-width: 1200px;
    padding: 0 15px;
    display: flex;
    height: 100%;
    margin: 0 auto 40px;
  }
  a {
    display: flex;
    height: 100%;
    width: 100%;
    .item {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: none;

      @media (max-width: 789px) {
        &.mobile {
          display: block;
        }
      }

      @media (min-width: 789px) {
        &.desktop {
          display: block;
        }
      }
    } 
  }
`;

export default function BannerInbound() {
  const result = useStaticQuery(graphql`
    {
      banner: prismicCampanhaHomeMeusAmbientes {
        data {
          banner {
            url
          }
          banner_mobile {
            url
          }
          link {
            url
          }
        }
      }
    }
  `)
  
  const { banner } = result
  return (
    <Container>
      <div className="wrapper flex">
        <a href={ banner.data.link && banner.data.link.url } target="_blank" rel="noreferrer">
          <div className="item desktop" style={ { backgroundImage: `url(${banner.data.banner && banner.data.banner.url})`}}></div>
          <div className="item mobile" style={ { backgroundImage: `url(${banner.data.banner_mobile && banner.data.banner_mobile.url})`}}></div>
        </a>
      </div>
    </Container>
  )
}
